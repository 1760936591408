<template>
	<div>
		<div id="sms">
			<table id="sms" class="table-sticky">
				<thead>
					<tr>
						<th class="child1">{{$t("administration.progdate")}}</th>
						<th class="child2">{{$t("common.message")}}</th>
						<th class="child3">{{$t("common.status")}}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="message in messages" :key="message.id">
						<td>
							{{ $d(message.date, "longlong") }}
						</td>
						<td>
							{{ message.text }}
						</td>
						<td>
							<span v-if="message.status != null && message.status != ''">{{ $t(`data.messagesstatus["${message.status}"]`) }}</span>
							<button v-else class="btn-mod fas fa-edit btn btn-outline-success" @click="editingSMS = message"/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<tr class= "button-add">
			<td class="td-button button-add" colspan="12">
				<button @click="editingSMS = {}" id="add-presta" class="btn-add fas fa-plus btn btn-primary" type="button"></button>
			</td>
		</tr>
		<EditSMS v-if="editingSMS" :data="editingSMS" v-on:close="editingSMS = null"/>
	</div>
</template>

<script>
	import dayjs from "dayjs"
	import api from "../../apiClient"
	import EditSMS from '../../modals/EditSMS.vue'

	export default {
		components: {
			EditSMS
		},
		data() {
			return {
				messages: [],
				editingSMS: null,
			}
		},
		mounted() {
			this.$api.campaigns.get().then(response => {
				let messages = response.data
				messages.sort( ( a, b ) => dayjs(b.date).diff(dayjs(a.date), "second") )
				messages.forEach(element => {
					element.date = new Date(element.date)
				})
				this.messages = messages
			}).catch(error => console.log(error))

			this.$api.campaigns.watch( event => {
				console.log( [ ...event.data ] )

				if ( event.method == "DELETE" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.messages.findIndex( msg => msg.id == event.data[i].id )
						if ( found >= 0 ) {
							this.messages.splice( found, 1 )
						}
					}
				} else if ( event.method == "PATCH" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.messages.find( msg => msg.id == event.data[i].id )
						if ( found ) {
							Object.assign( found, event.data[i] )
							found.date = new Date( found.date )
						}
					}
				} else if ( event.method == "POST" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let e = { ...event.data[i] }
						e.date = new Date( e.date )
						this.messages.push( e )
					}
					this.messages.sort( ( a, b ) => dayjs(b.date).diff(dayjs(a.date), "second") )
				}
			})
		}
	}
</script>
