<template>
	<Modal name="edit-service">
		<template v-slot:header>
			<h1 class="title-search">{{$t("common.sms")}}</h1>
		</template>
		<template v-slot:content>
			<div>
				<form class="form" @submit="$event.preventDefault()">
					<div id="when" class="form-group">
						<div class="radio radio-primary">
							<input id="now" class=" styled d-none" name="when" type="radio" value="now" v-model="when" :disabled="Object.keys(prefillData).length > 0">
							<label class="d-inline-block" for="now">
								<div class="radio-text">{{$t("sms.now")}}</div>
							</label>
						</div>
						<div class="radio radio-primary">
							<input id="future" class=" styled d-none" name="when" type="radio" value="future" v-model="when">
							<label class="d-inline-block" for="future">
								<div class="radio-text">{{$t("sms.scheduled")}}</div>
							</label>
						</div>
					</div>
					<div id="date" :class="`input-group date${ (missing.date && sms.date.length == 0 && getTitle().length == 0) ? ' required' : '' }${when == 'future' ? '' : ' hidden'}`" data-target-input="nearest">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.date")}}</span>
						</div>
						<input ref="date" class="form-control datetimepicker-input" data-target="#date" name="date" onkeydown="return false;" type="text"/>
						<span class="input-group-append" data-target="#date" data-toggle="datetimepicker">
							<div class="input-group-text">
								<i class="fa fa-calendar"></i>
							</div>
						</span>
					</div>
					<div class="message-wrapper">
						<div class="input-group form-group message">
							<textarea class="form-control" :maxlength="limit" :placeholder="$t('common.message')" v-model="sms.text">
							</textarea>
						</div>
						<div class="d-flex">
							<div class="count-left" :class="{ yellow: limit - sms.text.length < 60, red: limit - sms.text.length < 28 }">
								{{ $tc( "sms.charleft", limit - sms.text.length, { max: limit } ) }}
							</div>
						</div>
					</div>
					<div id="filters-group" class="form-group">
						<div class="sections row">
							<div class="">
								<h3 class="subtitle linetop">{{ $t('common.genders') }}</h3>
							</div>
							<div class="section">
								<div class="check-btn">
									<input id="female" class="d-none" name="gender" type="checkbox" v-model="female">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="female">{{$t("common.wom")}}</label>
								</div>
								<div class="check-btn">
									<input id="male" class="d-none" name="gender" type="checkbox" v-model="male">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="male">{{$t("common.men")}}</label>
								</div>
							</div>
						</div>
						<div class="sections row">
							<div class="">
								<h3 class="subtitle linetop">{{ $t('common.age') }}</h3>
							</div>
							<div class="section" id="ranges">
								<div class="check-btn">
									<input id="under25" class="d-none" name="gender" type="checkbox" v-model="under25">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="under25">{{$t("data.agegroups.under25")}}</label>
								</div>
								<div class="check-btn">
									<input id="range2545" class="d-none" name="gender" type="checkbox" v-model="range2545">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="range2545">{{$t("data.agegroups.range2545")}}</label>
								</div>
								<div class="check-btn">
									<input id="over45" class="d-none" name="gender" type="checkbox" v-model="over45">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="over45">{{$t("data.agegroups.over45")}}</label>
								</div>
							</div>
						</div>
					</div>
					<div id="recap" class="form-group">
						<div class="title">
						<!-- TODO : i18n -->
							<h3 class="subtitle linetop">Prévisions</h3>
						</div>
						<div>
							<span>SMS à envoyer : </span><span><b>{{ smsCount }}</b> ( ~{{ ( 0.076 * smsCount ).toFixed(2) }}€ )</span>
						</div>
						<div>
							<span>Notifications à envoyer : </span><span><b>{{ pushCount }}</b></span>
						</div>
						<div v-if="smsUnicode" id="unicode-alert" class="alert alert-warning">
							<b>Attention</b> : votre message contient des caractères spéciaux occasionnant une sur-facturation des SMS.
							<div class="unsupported">Caractères non-gérés : <b class="text-red">{{ smsSpecials.join(" ") }}</b></div>
							<button class="btn btn-primary" @click="smsReplaceUnicode">Remplacer par des caractères simples</button>
						</div>
					</div>
				</form>
			</div>
		</template>
		<template v-slot:buttons>
			<StatusButton v-if="data && Object.keys(data).length > 0" id="delete" type="danger" alert="modal" v-on:click="showDeleteConfirm = $event"><i class="fas fa-trash-alt"></i></StatusButton>
			<StatusButton id="validate" type="primary" alert="modal" @click="save( $event, true )"><i class="fas fa-check"></i></StatusButton>
			<StatusButton class="desktop" :class="{ hidden: when == 'now' }" id="apply" type="secondary" alert="modal" @click="save( $event, false )">{{$t("common.apply")}}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{$t( dataChanged ? "common.cancel" : "common.close")}}</button>
			<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
				{{ $t('sms.confirmdelete') }}
			</Confirm>
		</template>
	</Modal>
</template>

<script>
	import unorm from 'unorm'
	import dayjs from 'dayjs'
	import Modal from '../components/Modal.vue'
	import StatusButton from '../components/StatusButton.vue'
	import Confirm from "../modals/Confirm.vue"

	export default {
		components: {
			Modal,
			Confirm,
			StatusButton
		},
		props: [
			"data"
		],
		data() {
			return {
				limit: 160, // TODO : change to 70 for unicode languages (UCS-2 : Chinese, Arabic, ...)
				prefillData: this.data,
				sms: Object.assign( {
					date: null,
					text: "",
					status: null,
				}, this.data || {} ),
				smsUnicode: false,
				smsSpecials: [],
				when: ( this.data && this.data.date ) ? "future" : "now",
				female: false,
				male: false,
				under25: false,
				range2545: false,
				over45: false,
				users: [],
				smsCount: 0,
				pushCount: 0,
				missing: {},
				dataChanged: false,
				showDeleteConfirm: false,
				preventValidation: false
			}
		},
		watch: {
			female() {
				this.updateRecap()
			},
			male() {
				this.updateRecap()
			},
			under25() {
				this.updateRecap()
			},
			range2545() {
				this.updateRecap()
			},
			over45() {
				this.updateRecap()
			},
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							this.deleteSMS( callback )
						})
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							callback( -1 )
						})
					})
				}
			}
		},
		methods: {
			updateRecap() {
				try {
					let push = 0
					let sms = 0
					for ( let i = 0; i < this.users.length; i++ ) {
						const user = this.users[i]
						let ok_gender = false
						let ok_age = false
						if ( this.female && user.gender == "female" ) {
							ok_gender = true
						}
						if ( this.male && user.gender == "male" ) {
							ok_gender = true
						}
						if ( this.under25 && user.age < 25 ) {
							ok_age = true
						}
						if ( this.range2545 && user.age >= 25 && user.age <= 45 ) {
							ok_age = true
						}
						if ( this.over45 && user.age > 45 ) {
							ok_age = true
						}
						if ( ok_gender && ok_age ) {
							if ( user.fcm && user.fcm > 0 ) {
								push++
							} else if ( user.phone ) {
								sms++
							}
						}
					}
					this.pushCount = push
					const gsmCharset = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:;<=>?¡¿!"#¤%&()'*+,-./ÄÖÑÜ§äöñüà@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ \n`
					const gsmExtendedCharset = `€^{}[]~|`
					let smsLengthBits = 0
					let smsCharWidth = 7
					let smsPerMessage = 1
					let smsSpecials = []
					for ( let i = 0; i < this.sms.text.length; i++ ) {
						const ch = this.sms.text[i]
						if ( !gsmCharset.includes(ch) && !gsmExtendedCharset.includes(ch) ) {
							smsCharWidth = 16
							if ( !smsSpecials.includes(ch) ) {
								smsSpecials.push(ch)
							}
							//break
						}
					}
					if ( smsCharWidth == 16 ) {
						smsLengthBits = smsCharWidth * this.sms.text.length
					} else {
						for ( let i = 0; i < this.sms.text.length; i++ ) {
							const ch = this.sms.text[i]
							if ( gsmCharset.includes(ch) ) {
								smsLengthBits += 7
							} else if ( gsmExtendedCharset.includes(ch) ) {
								smsLengthBits += 14
							}
						}
					}
					if ( smsLengthBits > 1120 ) {
						smsPerMessage = Math.ceil( smsLengthBits / 1072 )
					}
					this.smsCount = sms * smsPerMessage
					if ( sms > 0 ) {
						this.smsUnicode = ( smsCharWidth > 7 )
						this.smsSpecials = smsSpecials
					} else {
						this.smsUnicode = false
						this.smsSpecials = []
					}
				} catch ( error ) {
					console.error( error )
				}
			},
			smsReplaceUnicode() {
				const gsmCharset = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:;<=>?¡¿!"#¤%&()'*+,-./ÄÖÑÜ§äöñüà@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ \n`
				const gsmExtendedCharset = `€^{}[]~|`
				const final = []
				for ( let i = 0; i < this.sms.text.length; i++ ) {
					const ch = this.sms.text[i]
					if ( gsmCharset.includes(ch) || gsmExtendedCharset.includes(ch) ) {
						final.push( ch )
					} else {
						final.push( unorm.nfd(ch).replace(/[\u0300-\u036f]/g, "") )
					}
				}
				this.sms.text = final.join("")
				this.updateRecap()
			},
			deleteSMS( callback ) {
				this.$api.campaign.delete( this.prefillData.id ).then( response => {
					callback( true )
					setTimeout( () => { this.preventValidation = false, this.$emit( 'close' ) }, 1000 )
				}).catch( error => {
					console.log( error )
					this.preventValidation = false
					callback( false, error.response.data.error )
				})
			},
			save( callback, closeAfter ) {
				if ( this.preventValidation ) {
					callback( -1 )
					return
				}
				this.preventValidation = true

				console.log( this.sms.date )
				if ( this.when != "now" && ( !this.sms.date || !dayjs(this.sms.date).isValid() ) ) {
					callback( false, this.$t( "errors.invaliddate" ) )
					this.preventValidation = false
					return
				}
				if ( !this.sms.text || this.sms.text.length == 0 ) {
					callback( false, this.$t( "errors.nomessage" ) )
					this.preventValidation = false
					return
				}
				if ( !this.female && !this.male ) {
					callback( false, this.$t( "errors.nogender" ) )
					this.preventValidation = false
					return
				}
				if ( !this.under25 && !this.range2545 && !this.over45 ) {
					callback( false, this.$t( "errors.noage" ) )
					this.preventValidation = false
					return
				}
				if ( this.when == "now" && this.pushCount == 0 && this.smsCount == 0 ) {
					callback( false, this.$t( "errors.campaign.norecipient" ) )
					this.preventValidation = false
					return
				}

				let data = { ...this.sms, genders: [], ranges: [] }
				if ( this.when == "now" ) {
					data.date = dayjs().add( 30, "minute" )
				}
				if ( this.female ) {
					data.genders.push( "female" )
				}
				if ( this.male ) {
					data.genders.push( "male" )
				}
				let agemap = []
				if ( this.under25 ) {
					for ( let i = 0; i < 25; i++ ) {
						agemap[i] = true
					}
				}
				if ( this.range2545 ) {
					for ( let i = 25; i < 45; i++ ) {
						agemap[i] = true
					}
				}
				if ( this.over45 ) {
					for ( let i = 45; i < 999; i++ ) {
						agemap[i] = true
					}
				}
				let inside = false
				for ( let i = 0; i < agemap.length + 1; i++ ) {
					if ( !inside && agemap[i] ) {
						data.ranges.push( i )
						inside = true
					} else if ( inside && !agemap[i] ) {
						data.ranges.push( i )
						inside = false
					}
				}

				data = {
					date: dayjs(data.date).toISOString(),
					text: data.text,
					genders: data.genders,
					ranges: data.ranges,
				}

				if ( this.prefillData && Object.keys(this.prefillData).length > 0 ) {
					let keys = [ "date", "ranges", "genders", "text" ]
					let differs = false
					for ( let i = 0; i < keys.length; i++ ) {
						const v = data[keys[i]]
						const isArr = Array.isArray(v)
						if ( ( isArr && !v.isSame(this.prefillData[keys[i]]) ) || ( !isArr && v != this.prefillData[keys[i]] ) ) {
							differs = true
							break
						}
					}
					if ( !differs ) {
						callback( -1 )
						this.preventValidation = false
						if ( closeAfter ) {
							this.$emit( 'close' )
						}
						return
					}
					this.$api.campaign.patch( this.prefillData.id, data ).then( response => {
						this.prefillData = { ...data, id: this.prefillData.id }
						callback( true )
						if ( closeAfter ) {
							setTimeout( () => { this.preventValidation = false, this.$emit( 'close' ) }, 1000 )
						} else {
							this.preventValidation = false
						}
					}).catch( error => {
						console.log( error )
						this.preventValidation = false
						callback( false, error.response.data.error )
					})
				} else {
					this.$api.campaigns.post( data ).then( response => {
						this.prefillData = { ...data, id: response.data[0].id }
						callback( true )
						if ( closeAfter ) {
							setTimeout( () => { this.preventValidation = false, this.$emit( 'close' ) }, 1000 )
						} else {
							this.preventValidation = false
						}
					}).catch( error => {
						console.log( error )
						this.preventValidation = false
						callback( false, error.response.data.error )
					})
				}
			}
		},
		created() {
		},
		mounted() {
			this.$nextTick( () => {
				if ( !this.data || Object.keys( this.data ).length == 0 ) {
					this.dataChanged = true
				} else {
					this.dataChanged = false
				}
			})
			$(this.$el).find('#date').datetimepicker({
				"format": this.$t( "time.defaultformat" ),
				"widgetPositioning": { "vertical": "bottom", "horizontal": "right" },
				"debug": ( process.env.VUE_APP_MODE === "development" ),
				"icons": { "time": "fa fa-clock" },
				"locale": this.$i18n.locale,
				"useCurrent": false,
				"minDate": dayjs(),
				"defaultDate": dayjs().add( 30, "minutes" ).format( "YYYY-MM-DD HH:mm" )
			})
			this.sms.date = dayjs().add( 30, "minutes" ).toISOString()
			if ( this.data && Object.keys(this.data).length > 0 ) {
				$(this.$el).find('#date').datetimepicker( "date", new Date(this.data.dateBase || this.data.date) )
				if ( this.data.genders.includes( "female" ) ) {
					this.female = true
				}
				if ( this.data.genders.includes( "male" ) ) {
					this.male = true
				}
				console.log(this.data.ranges)
				if ( this.data.ranges[0] == 0 ) {
					this.under25 = true
				}
				if ( this.data.ranges[this.data.ranges.length - 1] >= 100 ) {
					this.over45 = true
				}

				let ranges = [[this.data.ranges[0], this.data.ranges[1]]] // better: this.data.ranges.reduce((acc, el, i) => (acc[Math.floor(i / 2)] = [...(acc[Math.floor(i / 2)] || []), el], acc), [])
				if (this.data.ranges.length === 4)
					ranges.push([this.data.ranges[2], this.data.ranges[3]])
				if ( ranges.some(([min, max]) => min <= 25 && max >= 45) ) {
					this.range2545 = true
				}
			}
			this.$api.salonsusers.get({ "{fields}": [ "phone", "fcm", "gender", "birthdate" ], "estheticsusers.unregistration": "" }).then( response => {
				this.users = response.data
				this.users.forEach( user => {
					user.age = dayjs().diff( dayjs( user.birthdate ), "year" )
				})
				this.updateRecap()
			})
			$(this.$el).find('#date').on( "change.datetimepicker", (e) => {
				const now = dayjs()
				if ( dayjs(e.date).isBefore( now.hour(0).minute(0).second(0).millisecond(0).add(1, "day") ) ) {
					$(this.$el).find('#date').datetimepicker( "disabledTimeIntervals", [ [ moment({ h: 0 }), moment({ h: now.hour(), m: now.minute() + 30 }) ] ] )
				} else {
					$(this.$el).find('#date').datetimepicker( "disabledTimeIntervals", [] )
				}
				this.sms.date = dayjs(e.date).format( "YYYY-MM-DD HH:mm" )
			})
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/editsms.scss"/>

